import {
  ADD_PICKUP_REQUEST,
  EDIT_PICKUP_REQUEST,
  GET_PICKUP_REQUEST,
} from "../../types";

const initialState = {
  pickUpRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_PICKUP_REQUEST:
      return {
        ...state,
        pickUpRequestList: [payload, ...state.pickUpRequestList],
      };

    case EDIT_PICKUP_REQUEST:
      const updatedpickUpRequestList = state.pickUpRequestList.map(
        (serviceHistory) => {
          if (serviceHistory.id === payload.id) {
            return { ...serviceHistory, ...payload?.updateDetails };
          }
          return serviceHistory;
        }
      );
      return {
        ...state,
        pickUpRequestList: updatedpickUpRequestList,
      };

    case GET_PICKUP_REQUEST:
      return {
        ...state,
        pickUpRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
