import {
  ADD_PHOTOSHOOT_REQUEST,
  EDIT_PHOTOSHOOT_REQUEST,
  GET_PHOTOSHOOT_REQUEST,
} from "../types";

const initialState = {
  photoShootRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_PHOTOSHOOT_REQUEST:
      return {
        ...state,
        photoShootRequestList: [payload, ...state.photoShootRequestList],
      };

    case EDIT_PHOTOSHOOT_REQUEST:
      const updatedpickUpRequestList = state.photoShootRequestList.map(
        (photoShoot) => {
          if (photoShoot.id === payload.id) {
            return { ...photoShoot, ...payload?.updateDetails };
          }
          return photoShoot;
        }
      );
      return {
        ...state,
        photoShootRequestList: updatedpickUpRequestList,
      };

    case GET_PHOTOSHOOT_REQUEST:
      return {
        ...state,
        photoShootRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
