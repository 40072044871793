import style from "./style.module.scss";
import { Button } from "../Button";
import { ModalView } from "../ModalView";

const ConfirmModel = ({
  icon,
  heading,
  message,
  onSubmit,
  submitText,
  onClose,
  cancelText,
}) => {
  return (
    <ModalView>
      <div className={style["success-modal-container"]}>
        {icon && <img src={icon} alt="" />}
        <div>
          <p className={style["heading"]}>{heading}</p>
          <p className={style["message"]}>{message}</p>
        </div>
        <div className={style["btn_container"]}>
          <Button onClick={onClose} className={[style["btn"], style['negative']].join(' ')}>
            {cancelText || "Close"}
          </Button>

          <Button onClick={onSubmit} className={[style["btn"], style['positive']].join(' ')}>
            {submitText || "Continue"}
          </Button>
        </div>
      </div>
    </ModalView>
  );
};

export default ConfirmModel;
