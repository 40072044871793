import { BrowserRouter, useLocation } from "react-router-dom";
import { Router } from "./routes";
import store from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
const RouteChangeDetector = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname == "/dealer/new-request") return;
    sessionStorage.removeItem("newdealerprocess");
  }, [location]);

  return null;
};
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
        <RouteChangeDetector />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
