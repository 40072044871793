import { ADD_USER, EDIT_USER, GET_USER, GET_USER_DROPDOWN } from "../types";

const initialState = {
  userList: [],
  total: 0,
  userListDropdown: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_USER:
      return {
        ...state,
        userList: [payload, ...state.userList],
      };

    case EDIT_USER:
      const updatedUserList = state.userList.map((user) => {
        if (user.id === payload.id) {
          return { ...user, ...payload?.updatedata };
        }

        return user;
      });

      return {
        ...state,
        userList: updatedUserList,
      };

    case GET_USER:
      return {
        ...state,
        userList: payload?.data,
        total: payload?.total,
      };

    case GET_USER_DROPDOWN:
      return {
        ...state,
        userListDropdown: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
