import style from "./style.module.scss";

export const LoadingModal = () => {
	return (
		<div className={style["container"]}>
			<div className={style["wrapper"]}>
				<span className={style["circular-progressbar"]}></span>
			</div>
		</div>
	);
};
