import style from "./style.module.scss";
import { R } from "../../constants/resources";
import { useLocation } from "react-router-dom";
import { GetHeadings } from "../../constants/headings";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authAction";
import ConfirmModel from "../confirmModel/confirmModel";
import { useState } from "react";

export const Header = ({ onNavClick }) => {
  const location = useLocation();
  const headings = GetHeadings(location?.pathname);
  const { admin } = useSelector((state) => state?.auth);
  const fname = admin?.data?.fname;
  const email = admin?.data?.email;

  const [showPopup, setShowpopup] = useState(false);

  const dispatch = useDispatch();
  const hanldeLogout = () => {
    dispatch(logout({ id: admin?.data?.id }));
  };

  const [confirmLogout, setConfirmLogout] = useState(false);
  const tooglepopup = () => {
    setShowpopup((prev) => !prev);
  };

  const handleRemove = () => {
    setShowpopup(false);
  };

  return (
    <div className={style["container"]}>
      <div className={style["wrapper"]}>
        <h2 className={style["section-header"]}>{headings[0]}</h2>
        <div className={style["screen-heading"]}>
          {headings?.map((screenTitle, index) => {
            if (index > 0) return <p key={index}>{screenTitle}</p>;
            else return <></>;
          })}
        </div>
        <img
          onClick={onNavClick}
          className={style["menu-hamburger_mobile"]}
          src={R.ic_hamburger}
          alt={"Menu hamburger icon"}
        />
        <img
          className={style["action-button"]}
          src={R.ic_notification}
          alt=""
        />
        {/* <img
          className={style["action-button"]}
          src={R.ic_logout}
          alt=""
          onClick={() => setConfirmLogout(true)}
        />
          onClick={hanldeLogout}
        /> */}
        <div
          style={{ backgroundImage: `url(${R.ic_profile})` }}
          className={style["profile-pic"]}
          onClick={tooglepopup}
        ></div>
        {showPopup && (
          <div className={style["popup"]}>
            <div className={style["popup-first"]}>
              <div className="popup-second  ">
                <p>{fname}</p>
                <p>{email}</p>
              </div>
              <p className={style["remove-popup"]} onClick={handleRemove}>
                x
              </p>
            </div>
            <button
              onClick={() => {
                handleRemove();
                setConfirmLogout(true);
              }}
            >
              LOGOUT
            </button>
          </div>
        )}
      </div>
      <div className={style["heading-container_mobile"]}>
        <h2 className={style["section-heading_mobile"]}>{headings[0]}</h2>
        {headings?.map((screenTitle, index) => {
          if (index > 0) return <p key={index}>{screenTitle}</p>;
          else return <></>;
        })}
      </div>

      {confirmLogout && (
        <ConfirmModel
          heading={"Logout"}
          message={"Are you sure you want to log out?"}
          onSubmit={hanldeLogout}
          onClose={() => setConfirmLogout(false)}
        />
      )}
    </div>
  );
};
