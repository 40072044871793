import {
  ADD_CREATIVE_MAKING_REQUEST,
  EDIT_CREATIVE_MAKING_REQUEST,
  GET_CREATIVE_MAKING_REQUEST,
  GET_CREATIVE_MAKING_REQUEST_DETAIL,
} from "../types";

const initialState = {
  creativeMakingRequestList: [],
  creativeMakingRequestDetail: {},
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_CREATIVE_MAKING_REQUEST:
      return {
        ...state,
        creativeMakingRequestList: [
          payload,
          ...state.creativeMakingRequestList,
        ],
      };

    case EDIT_CREATIVE_MAKING_REQUEST:
      const updatedpickUpRequestList = state.creativeMakingRequestList.map(
        (creativeMaking) => {
          if (creativeMaking.id === payload.id) {
            return { ...creativeMaking, ...payload?.updateDetails };
          }
          return creativeMaking;
        }
      );
      return {
        ...state,
        creativeMakingRequestList: updatedpickUpRequestList,
      };

    case GET_CREATIVE_MAKING_REQUEST:
      return {
        ...state,
        creativeMakingRequestList: payload?.data,
        total: payload?.total,
      };
    case GET_CREATIVE_MAKING_REQUEST_DETAIL:
      return {
        ...state,
        creativeMakingRequestDetail: payload,
      };
    default:
      return state;
  }
}
