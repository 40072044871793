import {
  ADD_ROLE,
  EDIT_ROLE,
  GET_PERMISSION,
  GET_ROLE,
  GET_ROLE_PERMISSION,
} from "../types";

const initialState = {
  roleList: [],
  total: 20,
  permissionList: [],
  rolePermissionList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ROLE:
      return {
        ...state,
        roleList: [payload, ...state.roleList],
      };

    case EDIT_ROLE:
      const updatedRoleList = state.roleList.map((role) => {
        if (role.id === payload.role_id) {
          return {
            ...role,
            status: role?.status === "active" ? "inactive" : "active",
          };
        }
        return role;
      });

      return {
        ...state,
        roleList: updatedRoleList,
      };

    case GET_ROLE:
      return {
        ...state,
        roleList: payload?.data,
        total: payload?.total,
      };

    case GET_PERMISSION:
      return {
        ...state,
        permissionList: payload,
      };

    case GET_ROLE_PERMISSION:
      return {
        ...state,
        rolePermissionList: payload,
      };

    default:
      return state;
  }
}
