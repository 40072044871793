import style from "./style.module.scss";

export const Button = ({
  type,
  onClick,
  onFocus,
  children: text,
  className = "",
  Style = {},
  containerStyle,
  transparent,
  disabled =  false
}) => {

  if (transparent)
    className = [className, style["transparent"]].join(' ');

  return (
    <div className={style["container"]} style={containerStyle}>
      <button
        disabled={disabled}
        type={type}
        className={className}
        style={Style}
        onClick={onClick}
        onFocus={onFocus}
      >
        {text}
      </button>
    </div>
  );
};
