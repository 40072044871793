import { Header } from "../../components/header";
import { Sidebar } from "../../components/sidebar";
import style from "./style.module.scss";
import { Outlet } from "react-router-dom";
import {useEffect, useRef, useState} from "react";

export const MainLayout = () => {
  const [screenStack, setScreenStack] = useState([]);

  const [showSidebar, setShowSidebar] = useState(false);

  const sideBarContainerRef = useRef(null);

  useEffect(() => {
    if(!sideBarContainerRef) return;
    if(showSidebar) {
      sideBarContainerRef.current.style.left = "0"
      sideBarContainerRef.current.style.right = "auto"
    }
      else {
      sideBarContainerRef.current.style.left = "auto"
      sideBarContainerRef.current.style.right = "100%"
    }
  }, [showSidebar]);

  return (
    <div className={style["container"]}>
      <div className={style['sidebar-container']}>
        <Sidebar screenStack={screenStack} setScreenStack={setScreenStack} />
      </div>
      <div ref={sideBarContainerRef} className={style['sidebar-container_mobile']}>
        <Sidebar
          modal={true}
          onDismissRequest={() => setShowSidebar(false)}
          collapsible={false}
          screenStack={screenStack}
          setScreenStack={setScreenStack}
        />
      </div>
      <div className={style["content-container"]}>
        <Header onNavClick={() => setShowSidebar(true)} screenStack={screenStack} />
        <div className={style["pages-wrapper"]}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
