import {
  ADD_SOCIALMEDIA_REQUEST,
  EDIT_SOCIALMEDIA_REQUEST,
  GET_SOCIALMEDIA_REQUEST,
} from "../types";

const initialState = {
  socialMediaRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_SOCIALMEDIA_REQUEST:
      return {
        ...state,
        socialMediaRequestList: [payload, ...state.socialMediaRequestList],
      };

    case EDIT_SOCIALMEDIA_REQUEST:
      const updatedsocialMediaRequestList = state.socialMediaRequestList.map(
        (socialMedia) => {
          if (socialMedia.id === payload.id) {
            return { ...socialMedia, ...payload?.updateDetails };
          }
          return socialMedia;
        }
      );
      return {
        ...state,
        socialMediaRequestList: updatedsocialMediaRequestList,
      };

    case GET_SOCIALMEDIA_REQUEST:
      return {
        ...state,
        socialMediaRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
