import {
  ADD_DM_REQUEST,
  EDIT_DM_REQUEST,
  EDIT_PHOTOSHOOT_REQUEST,
  GET_DM_REQUEST,
  GET_PHOTOSHOOT_REQUEST,
} from "../types";

const initialState = {
  DMRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DM_REQUEST:
      return {
        ...state,
        DMRequestList: [payload, ...state.DMRequestList],
      };

    case EDIT_DM_REQUEST:
      const UpdatedRequestList = state.DMRequestList.map(
        (dm) => {
          if (dm.id === payload.id) {
            return { ...dm, ...payload?.updateDetails };
          }
          return dm;
        }
      );
      return {
        ...state,
        DMRequestList: UpdatedRequestList,
      };

    case GET_DM_REQUEST:
      return {
        ...state,
        DMRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
