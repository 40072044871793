import {
  ADD_WEBSITEDEVELOPMENT_REQUEST,
  EDIT_WEBSITEDEVELOPMENT_REQUEST,
  GET_WEBSITEDEVELOPMENT_REQUEST,
} from "../types";

const initialState = {
  websiteDevelopmentRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_WEBSITEDEVELOPMENT_REQUEST:
      return {
        ...state,
        websiteDevelopmentRequestList: [
          payload,
          ...state.websiteDevelopmentRequestList,
        ],
      };

    case EDIT_WEBSITEDEVELOPMENT_REQUEST:
      const updatedwebsiteDevelopmentRequestList =
        state.websiteDevelopmentRequestList.map((websiteDevelopment) => {
          if (websiteDevelopment.id === payload.id) {
            return { ...websiteDevelopment, ...payload?.updateDetails };
          }
          return websiteDevelopment;
        });
      return {
        ...state,
        websiteDevelopmentRequestList: updatedwebsiteDevelopmentRequestList,
      };

    case GET_WEBSITEDEVELOPMENT_REQUEST:
      return {
        ...state,
        websiteDevelopmentRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
