import {
  GET_INSURANCE_REQUEST,
  ADD_INSURANCE_DETAILS,
  ADD_INSURANCE_PAYOUTS,
  GET_INSURANCE_PAYOUTS,
  EDIT_INSURANCE_DETAILS,
  EDIT_INSURANCE_PAYOUTS,
  ADD_INSURANCE_KYC_DETAILS,
  EDIT_INSURANCE_KYC_DETAILS,
  ADD_INSURANCE_CUSTOMER_DETAILS,
  GET_INSURANCE_CUSTOMER_DETAILS,
  EDIT_INSURANCE_CUSTOMER_DETAILS,
  GET_INSURANCE_CUSTOMER_DOC,
  GET_INSURANCE_DETAILS_STEP,
  GET_INSURANCE_PARTNER_CASES_STEP,
  REMOVE_LOAN_INSURANCE,
} from "../../types";

const initialState = {
  insuranceRequestList: [],
  basicDetails: {},
  kycDetails: {},
  total: 0,
  insuranceDetails: {},
  insurancePayouts: [],
  getInsCusData: {},
  getInsCusDoc: {},
  getInsPayoutDetails: {},
  get_Insurance_Details_Step: {},
  get_Ins_Partner_Cases_Step: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_INSURANCE_CUSTOMER_DETAILS:
      return {
        ...state,
        basicDetails: { ...payload, ...state.basicDetails },
      };

    case EDIT_INSURANCE_CUSTOMER_DETAILS:
      return {
        ...state,
        basicDetails: { ...payload, ...state },
      };

    case ADD_INSURANCE_KYC_DETAILS:
      return {
        ...state,
        kycDetails: { ...payload, ...state.kycDetails },
      };

    case ADD_INSURANCE_DETAILS:
      return {
        ...state,
        insuranceDetails: { ...payload, ...state.insuranceDetails },
      };

    case EDIT_INSURANCE_DETAILS:
      return {
        ...state,
        insuranceDetails: { ...payload, ...state },
      };

    case ADD_INSURANCE_PAYOUTS:
      return {
        ...state,
        insurancePayouts: [...state?.insurancePayouts, { ...payload }],
      };

    case EDIT_INSURANCE_PAYOUTS:
      return {
        ...state,
        insurancePayouts: state.insurancePayouts.map((item, i) => {
          if (payload.index === i) {
            return { ...item, ...payload.data };
          }
          return item;
        }),
      };

    case GET_INSURANCE_REQUEST:
      return {
        ...state,
        insuranceRequestList: payload?.data,
        total: payload?.total,
      };

    case GET_INSURANCE_CUSTOMER_DETAILS:
      return {
        ...state,
        getInsCusData: payload?.data,
      };

    case GET_INSURANCE_CUSTOMER_DOC:
      return {
        ...state,
        getInsCusDoc: payload?.data,
      };

    case GET_INSURANCE_PAYOUTS:
      return {
        ...state,
        getInsPayoutDetails: payload?.data,
      };

    case GET_INSURANCE_DETAILS_STEP:
      return {
        ...state,
        get_Insurance_Details_Step: payload?.data[0],
      };

    case GET_INSURANCE_PARTNER_CASES_STEP:
      return {
        ...state,
        get_Ins_Partner_Cases_Step: payload?.data,
      };

    case REMOVE_LOAN_INSURANCE:
      return {
        basicDetails: {},
        kycDetails: {},
        total: 0,
        insuranceDetails: {},
        insurancePayouts: [],
        getInsCusData: {},
        getInsCusDoc: {},
        getInsPayoutDetails: {},
        get_Insurance_Details_Step: {},
        get_Ins_Partner_Cases_Step: {},
      };

    default:
      return state;
  }
}
