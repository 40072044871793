import {
  ADD_SERVICE_HISTORY,
  EDIT_SERVICE_HISTORY,
  GET_SERVICE_HISTORY,
} from "../types";

const initialState = {
  serviceHistoryList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_SERVICE_HISTORY:
      return {
        ...state,
        serviceHistoryList: [payload, ...state.serviceHistoryList],
      };

    case EDIT_SERVICE_HISTORY:
      const updatedServiceHistoryList = state.serviceHistoryList.map(
        (serviceHistory) => {
          if (serviceHistory.id === payload.id) {
            return { ...serviceHistory, ...payload?.updateDetails };
          }
          return serviceHistory;
        }
      );
      return {
        ...state,
        serviceHistoryList: updatedServiceHistoryList,
      };

    case GET_SERVICE_HISTORY:
      return {
        ...state,
        serviceHistoryList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
