import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  INIT_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types";

const initialState = {
  isLoggedIn: false,
  admin: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        admin: payload.admin,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case INIT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        admin: payload,
      };
    }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        admin: null,
      };

    default:
      return state;
  }
}
