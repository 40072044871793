import axios from "axios";
import { call } from "./";
import { Navigate, useNavigate } from "react-router";
import { showToast } from "../components/toaster/Toaster";
// import { toast } from "react-toastify";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

class AuthApi {
  login(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "login", null, data);
          setSession(res?.data?.token?.accessToken);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  forgotOtp(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "send-otp", null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  verifyOtp(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "verify-otp", null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  resetPassword(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("put", "update-password", null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updatePassword(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "check-hash-validity", null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getuserbytoken() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      
      (async () => {
        try {
          const res = await call("get", "get-user", null, null, token);
          
          resolve(res.data);
        } catch (err) {
          if (
            err.response.data.message === "User was logged out from the session"
          ) {
            showToast(err.response.data.message, "error");

            <Navigate to="/login" />;
          }
          resolve(null);
        }
      })();
    });
  }

  logout(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "logout", null, params, token);
          setSession();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const authApi = new AuthApi();
