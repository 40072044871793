import {
  INIT_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types";
import { authApi } from "../../api/authApi";

export const login = (data) => (dispatch) => {
  return authApi.login(data).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { admin: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );
};

export const forgotPassword = (data) => (dispatch) => {
  return authApi.forgotOtp(data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const verifyOtp = (data) => (dispatch) => {
  return authApi.verifyOtp(data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const resetPassword = (data) => (dispatch) => {
  return authApi.resetPassword(data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch) => {
  return authApi.logout().then(
    (response) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const loadUser = (onSettled) => {
  return async (dispatch) => {
    let user = null;
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      user = await authApi.getuserbytoken();
    }
    if (user) {
      dispatch({
        type: INIT_SUCCESS,
        payload: user,
      });
    }
    onSettled();
  };
};
