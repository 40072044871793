import {
  ADD_RTO_CASES_REQUEST,
  EDIT_RTO_CASES_REQUEST,
  GET_RTO_CASES_REQUEST,
} from "../../types";

const initialState = {
  rtoCasesRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_RTO_CASES_REQUEST:
      return {
        ...state,
        rtoCasesRequestList: [payload, ...state.rtoCasesRequestList],
      };

    case EDIT_RTO_CASES_REQUEST:
      const updatedrtoCasesRequestList = state.rtoCasesRequestList.map(
        (serviceHistory) => {
          if (serviceHistory.id === payload.id) {
            return { ...serviceHistory, ...payload?.updateDetails };
          }
          return serviceHistory;
        }
      );
      return {
        ...state,
        rtoCasesRequestList: updatedrtoCasesRequestList,
      };

    case GET_RTO_CASES_REQUEST:
      return {
        ...state,
        rtoCasesRequestList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
