import {
  ADD_BANK_TRANSACTION,
  GET_BANK_TRANSACTION,
  GET_OUTSTANDING_BANK_TRANSACTION,
} from "../types";

const initialState = {
  transactionList: [],
  outstandingTransactionList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BANK_TRANSACTION:
      return {
        ...state,
        transactionList: [payload, ...state.transactionList],
      };

    case GET_BANK_TRANSACTION:
      return {
        ...state,
        transactionList: payload?.data,
        total: payload?.total,
      };

    case GET_OUTSTANDING_BANK_TRANSACTION:
      return {
        ...state,
        outstandingTransactionList: payload?.data,
        total: payload?.total,
      };

    default:
      return state;
  }
}
