import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { MainLayout } from "../pages/main";
import { AUTH_ROUTES, MAIN_ROUTES } from "../constants/routes";
import { WithSuspense } from "../components/SuspenseWidget";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../store/auth/authAction";
import { LoadingModal } from "../components/LoadingModal";
import { getUserPermissions } from "../util/Permissions.ts";

const SignInScreen = lazy(() => import("../pages/auth/sign-in"));
const ResetPassScreen = lazy(() => import("../pages/auth/reset-pass"));
const ChangePassScreen = lazy(() => import("../pages/auth/change-pass"));
const DashboardScreen = lazy(() => import("../pages/main/dashboard"));
const UserAndPermissionsScreen = lazy(() =>
  import("../pages/main/user-permission")
);

const UsersScreen = lazy(() => import("../pages/main/master-management/users"));

const WebsiteHeading = lazy(() => import("../pages/main/dealer/new-dealer/website_heading/"))

const ViewUser = lazy(() =>
  import("../pages/main/master-management/users/view-user")
);
const NewUserScreen = lazy(() =>
  import("../pages/main/master-management/users/new-user")
);
const RolesScreen = lazy(() => import("../pages/main/master-management/roles"));
const ViewRole = lazy(() =>
  import("../pages/main/master-management/roles/view-role")
);
const NewRoleScreen = lazy(() =>
  import("../pages/main/master-management/roles/new-role")
);
const DealersScreen = lazy(() => import("../pages/main/dealer"));
const PrivacyPolicyScreen = lazy(() =>
  import("../pages/main/static-pages/privacy")
);
const TermsConditionsScreen = lazy(() =>
  import("../pages/main/static-pages/t&c")
);
const LoanLenderScreen = lazy(() =>
  import("../pages/main/static-pages/loan-lender")
);
const InsuranceLenderScreen = lazy(() =>
  import("../pages/main/static-pages/insurance-lender")
);
const NewDealerScreen = lazy(() => import("../pages/main/dealer/new-dealer"));
const DealerPrefrence = lazy(() => import ("../pages/main/dealer/dealerPrefrence"))
const ViewDealerScreen = lazy(() => import("../pages/main/dealer/view-dealer"));


const ServiceHistory = lazy(() =>
  import("../pages/main/services/ServiceHistory/")
);
const NewService = lazy(() =>
  import("../pages/main/services/ServiceHistory/new")
);
const ViewService = lazy(() =>
  import("../pages/main/services/ServiceHistory/view")
);
const EditService = lazy(() =>
  import("../pages/main/services/ServiceHistory/edit")
);
const UploadDealer = lazy(() =>
  import("../pages/main/dealer/UploadDocument/new")
);
const UploadDelaersDocument = lazy(() =>
  import("../pages/main/dealer/UploadDocument/upload/")
);

const PickupRequestsScreen = lazy(() =>
  import("../pages/main/services/DoorServices")
);
const AddDoorService = lazy(() =>
  import("../pages/main/services/DoorServices/new")
);
const EditDoorService = lazy(() =>
  import("../pages/main/services/DoorServices/edit")
);
const ViewDoorService = lazy(() =>
  import("../pages/main/services/DoorServices/view")
);

const RTOCasesScreen = lazy(() => import("../pages/main/services/RTOCases"));
const NewRTOCasesScreen = lazy(() =>
  import("../pages/main/services/RTOCases/new")
);
const ViewRTOCasesScreen = lazy(() =>
  import("../pages/main/services/RTOCases/view")
);
const EditRTOCasesScreen = lazy(() =>
  import("../pages/main/services/RTOCases/edit")
);

const ChallanList = lazy(() => import("../pages/main/services/Challan"));

const NewChallanRequest = lazy(() =>
  import("../pages/main/services/Challan/new-request")
);
const NewChallan = lazy(() => import("../pages/main/services/Challan/new"));
const EditChallan = lazy(() => import("../pages/main/services/Challan/edit"));
const ViewChallan = lazy(() => import("../pages/main/services/Challan/view"));

//
const NewDMScreen = lazy(() =>
  import("../pages/main/services/DigitalMarketing/new")
);
const ListDMScreen = lazy(() =>
  import("../pages/main/services/DigitalMarketing")
);
const EditDMScreen = lazy(() =>
  import("../pages/main/services/DigitalMarketing/edit")
);
const ViewDMScreen = lazy(() =>
  import("../pages/main/services/DigitalMarketing/view")
);

// const TransactionScreen = lazy(() => import("../pages/main/transaction"));
const BankTransactionScreen = lazy(() =>
  import("../pages/main/transaction/bank-transaction")
);
const AddBankTransactionScreen = lazy(() =>
  import("../pages/main/transaction/bank-transaction/new")
);
const OutstandingTransactionScreen = lazy(() =>
  import("../pages/main/transaction/outstanding-transaction")
);
const ServiceTransactionScreen = lazy(() =>
  import("../pages/main/transaction/service-transaction")
);

const InventoryScreen = lazy(() => import("../pages/main/inventory"));
const LeadListScreen = lazy(() => import("../pages/main/lead"));
const EditCarDetails = lazy(() =>
  import("../pages/main/edit-car-details/EditCarDetails.jsx")
);

const UpdateCarImages = lazy(() =>
  import("../pages/main/edit-car-details/UpdateCarImages.jsx")
);


//Customer-Lead-Management
const CustomerLead = lazy(() => import("../pages/main/lead/customer-lead"));
const CustomerPreferences = lazy(()=> import("../pages/main/lead/customer-preferences"));
const AssignDealers = lazy(()=> import("../pages/main/lead/customer-preferences/step2"));

const InventoryListScreen = lazy(() =>
  import("../pages/main/inventory/inventory-list")
);

const NewInventoryRequest = lazy(() =>
  import("../pages/main/inventory/inventory-list/newInventory")
);
const PhotoShootScreen = lazy(() =>
  import("../pages/main/services/Photoshoot")
);
const AddPhotoShootScreen = lazy(() =>
  import("../pages/main/services/Photoshoot/new")
);
const ViewPhotoShootScreen = lazy(() =>
  import("../pages/main/services/Photoshoot/view")
);
const EditPhotoShootScreen = lazy(() =>
  import("../pages/main/services/Photoshoot/edit")
);

const SocialMediaScreen = lazy(() =>
  import("../pages/main/services/SocialMedia")
);
const AddSocialMediaScreen = lazy(() =>
  import("../pages/main/services/SocialMedia/new")
);
const ViewSocialMediaScreen = lazy(() =>
  import("../pages/main/services/SocialMedia/view")
);
const EditSocialMediaScreen = lazy(() =>
  import("../pages/main/services/SocialMedia/edit")
);

const FinancialServicesScreen = lazy(() =>
  import("../pages/main/financial-services")
);

const LoansListScreen = lazy(() =>
  import("../pages/main/financial-services/loans")
);

const AddLoanScreen = lazy(() =>
  import("../pages/main/financial-services/loans/new")
);
const EditLoanScreen = lazy(() =>
  import("../pages/main/financial-services/loans/edit")
);
const ViewLoanScreen = lazy(() =>
  import("../pages/main/financial-services/loans/view")
);

const WebsiteDevelopmentScreen = lazy(() =>
  import("../pages/main/services/WebsiteDevelopment")
);
const AddWebsiteDevelopmentScreen = lazy(() =>
  import("../pages/main/services/WebsiteDevelopment/new")
);
const ViewWebsiteDevelopmentScreen = lazy(() =>
  import("../pages/main/services/WebsiteDevelopment/view")
);
const EditWebsiteDevelopmentScreen = lazy(() =>
  import("../pages/main/services/WebsiteDevelopment/edit")
);

//
const InsuranceListScreen = lazy(() =>
  import("../pages/main/financial-services/loanInsurance")
);

const AddInsuranceScreen = lazy(() =>
  import("../pages/main/financial-services/loanInsurance/new")
);
const EditInsuranceScreen = lazy(() =>
  import("../pages/main/financial-services/loanInsurance/edit")
);
const ViewInsuranceScreen = lazy(() =>
  import("../pages/main/financial-services/loanInsurance/view")
);
const CreativeMakingScreen = lazy(() =>
  import("../pages/main/services/CreativeMaking")
);
const AddCreativeMakingScreen = lazy(() =>
  import("../pages/main/services/CreativeMaking/new")
);
const ViewCreativeMakingScreen = lazy(() =>
  import("../pages/main/services/CreativeMaking/view")
);
const EditCreativeMakingScreenScreen = lazy(() =>
  import("../pages/main/services/CreativeMaking/edit")
);
const ViewVahanCheck = lazy(() =>
  import("../pages/main/services/VahanCheck/view")
);

const VahanCheckListScreen = lazy(() =>
  import("../pages/main/services/VahanCheck")
);

const AddVahanCheck = lazy(() =>
  import("../pages/main/services/VahanCheck/add")
);
export const Router = () => {
  const dispatch = useDispatch();

  const [loadingUser, setLoadingUser] = useState(true);
  const onUserLoadingFinished = () => {
    setLoadingUser(false);
  };

  useEffect(() => {
    dispatch(loadUser(() => onUserLoadingFinished()));
  }, []);

  const { admin, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    getUserPermissions(admin?.roles);
  }, [admin]);

  if (loadingUser) {
    return <LoadingModal />;
  } else return isLoggedIn ? <MainRoutes /> : <AuthRoutes />;
};

const AuthRoutes = () => {
  return (
    <Routes>
      {/** Index route will redirect to mentioned initial route,
			 @element - initial route `string` in Navigate component */}
      <Route index element={<Navigate to={AUTH_ROUTES.SIGNIN} />} />

      <Route
        path={AUTH_ROUTES.SIGNIN}
        element={<WithSuspense load={<SignInScreen />} />}
      />
      <Route
        path={AUTH_ROUTES.RESET_PASS}
        element={<WithSuspense load={<ResetPassScreen />} />}
      />
      <Route
        path={AUTH_ROUTES.CHANGE_PASS}
        element={<WithSuspense load={<ChangePassScreen />} />}
      />
      <Route
        path={MAIN_ROUTES.PRIVACY_POLICY}
        element={<WithSuspense load={<PrivacyPolicyScreen />} />}
      />
      <Route
        path={MAIN_ROUTES.TERMS_CONDITIONS}
        element={<WithSuspense load={<TermsConditionsScreen />} />}
      />
      <Route
        path={MAIN_ROUTES.LOAN_LENDER}
        element={<WithSuspense load={<LoanLenderScreen />} />}
      />
      <Route
        path={MAIN_ROUTES.INSURANCE_LENDER}
        element={<WithSuspense load={<InsuranceLenderScreen />} />}
      />

      {/** Wildcard route will redirect all unhandled route to base route,
			 @element - base route `string` in Navigate component */}
      <Route path={"*"} element={<Navigate to={"/"} />} />
    </Routes>
  );
};

const MainRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<MainLayout />}>
        {/** Index route will redirect to mentioned initial route,
				 @element - initial route `string` in Navigate component */}
        <Route index element={<Navigate to={MAIN_ROUTES.DASHBOARD} />} />

        <Route
          path={MAIN_ROUTES.DASHBOARD}
          element={<WithSuspense load={<DashboardScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.PRIVACY_POLICY}
          element={<WithSuspense load={<PrivacyPolicyScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.TERMS_CONDITIONS}
          element={<WithSuspense load={<TermsConditionsScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.LOAN_LENDER}
          element={<WithSuspense load={<LoanLenderScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.INSURANCE_LENDER}
          element={<WithSuspense load={<InsuranceLenderScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.DEALERS}
          element={<WithSuspense load={<DealersScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.NEW_DEALER}
          element={<WithSuspense load={<NewDealerScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.EDIT_DEALER}
          element={<WithSuspense load={<NewDealerScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.DEALER_PREFERENCE}
          element={<WithSuspense load={<DealerPrefrence />} />}
        />
        <Route
          path={MAIN_ROUTES.VIEW_DEALER}
          element={<WithSuspense load={<ViewDealerScreen />} />}
        />
        <Route
          path={MAIN_ROUTES.UPLOAD_DEALER}
          element={<WithSuspense load={<UploadDealer />} />}
        />
        <Route
          path={MAIN_ROUTES.DOCUMENT_UPLOAD}
          element={<WithSuspense load={<UploadDelaersDocument />} />}
        />
        <Route
          path={MAIN_ROUTES.WEBSITE_HEADING}
          element={<WithSuspense load={<WebsiteHeading />}/>}
        />  
        <Route
          path={MAIN_ROUTES.USER_AND_PERMISSION}
          element={<WithSuspense load={<UserAndPermissionsScreen />} />}
        />

        {/** Master management routes */}
        <Route path={MAIN_ROUTES.MASTER_MANAGEMENT.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}
          <Route
            index
            element={<Navigate to={MAIN_ROUTES.MASTER_MANAGEMENT.USERS} />}
          />

          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.USERS}
            element={<WithSuspense load={<UsersScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.NEW_USER}
            element={<WithSuspense load={<NewUserScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.VIEW_USER}
            element={<WithSuspense load={<ViewUser />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.ROLES}
            element={<WithSuspense load={<RolesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.NEW_ROLE}
            element={<WithSuspense load={<NewRoleScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.EDIT_ROLE}
            element={<WithSuspense load={<NewRoleScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.MASTER_MANAGEMENT.VIEW_ROLE}
            element={<WithSuspense load={<ViewRole />} />}
          />

          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.MASTER_MANAGEMENT.ROUTE} />}
          />
        </Route>

        {/**RTO Services routes */}
        <Route path={MAIN_ROUTES.RTO_SERVICES.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}

          <Route
            index
            element={<Navigate to={MAIN_ROUTES.RTO_SERVICES.PICKUP_REQUEST} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.PICKUP_REQUEST}
            element={<WithSuspense load={<PickupRequestsScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_DOOR_SERVICE}
            element={<WithSuspense load={<AddDoorService />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_DOOR_SERVICE}
            element={<WithSuspense load={<EditDoorService />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_DOOR_SERVICE}
            element={<WithSuspense load={<ViewDoorService />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.RTO_CASES}
            element={<WithSuspense load={<RTOCasesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_RTO_CASE}
            element={<WithSuspense load={<NewRTOCasesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_RTO_CASE}
            element={<WithSuspense load={<ViewRTOCasesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_RTO_CASE}
            element={<WithSuspense load={<EditRTOCasesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.CHALLAN}
            element={<WithSuspense load={<ChallanList />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.NEW_CHALLAN_REQUEST}
            element={<WithSuspense load={<NewChallanRequest />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_CHALLAN}
            element={<WithSuspense load={<NewChallan />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_CHALLAN}
            element={<WithSuspense load={<EditChallan />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_CHALLAN}
            element={<WithSuspense load={<ViewChallan />} />}
          />
          {/* Photoshoot */}
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.PHOTO_SHOOT}
            element={<WithSuspense load={<PhotoShootScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_PHOTO_SHOOT}
            element={<WithSuspense load={<AddPhotoShootScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_PHOTO_SHOOT}
            element={<WithSuspense load={<EditPhotoShootScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_PHOTO_SHOOT}
            element={<WithSuspense load={<ViewPhotoShootScreen />} />}
          />
          {/* Social Media */}
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.SOCIAL_MEDIA}
            element={<WithSuspense load={<SocialMediaScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_SOCIAL_MEDIA}
            element={<WithSuspense load={<AddSocialMediaScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_SOCIAL_MEDIA}
            element={<WithSuspense load={<EditSocialMediaScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_SOCIAL_MEDIA}
            element={<WithSuspense load={<ViewSocialMediaScreen />} />}
          />

          {/* Website Development Media */}
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.WEBSITE_DEVELOPMENT}
            element={<WithSuspense load={<WebsiteDevelopmentScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_WEBSITE_DEVELOPMENT}
            element={<WithSuspense load={<AddWebsiteDevelopmentScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_WEBSITE_DEVELOPMENT}
            element={<WithSuspense load={<EditWebsiteDevelopmentScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_WEBSITE_DEVELOPMENT}
            element={<WithSuspense load={<ViewWebsiteDevelopmentScreen />} />}
          />

          {/* Digital Marketing */}
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_DIGITAL_MARKETING}
            element={<WithSuspense load={<NewDMScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.DIGITAL_MARKETING}
            element={<WithSuspense load={<ListDMScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_DIGITAL_MARKETING}
            element={<WithSuspense load={<EditDMScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_DIGITAL_MARKETING}
            element={<WithSuspense load={<ViewDMScreen />} />}
          />

          {/* Creative Marketing */}
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.CREATIVE_MAKING}
            element={<WithSuspense load={<CreativeMakingScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_CREATIVE_MAKING}
            element={<WithSuspense load={<AddCreativeMakingScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.EDIT_CREATIVE_MAKING}
            element={<WithSuspense load={<EditCreativeMakingScreenScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_CREATIVE_MAKING}
            element={<WithSuspense load={<ViewCreativeMakingScreen />} />}
          />

          {/* {Vahan Check} */}

          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VIEW_VAHAN_CHECK}
            element={<WithSuspense load={<ViewVahanCheck />} />}
          />

          <Route
            path={MAIN_ROUTES.RTO_SERVICES.VAHAN_CHECK}
            element={<WithSuspense load={<VahanCheckListScreen />} />}
          />

          <Route
            path={MAIN_ROUTES.RTO_SERVICES.ADD_VAHAN_CHECK}
            element={<WithSuspense load={<AddVahanCheck />} />}
          />
          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.RTO_SERVICES.ROUTE} />}
          />
        </Route>

        {/**RTO Service History routes */}
        <Route path={MAIN_ROUTES.SERVICE_HISTORY.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
           @element - initial route `string` */}

          <Route
            index
            element={<Navigate to={MAIN_ROUTES.SERVICE_HISTORY.SERVICE_LIST} />}
          />
          <Route
            path={MAIN_ROUTES.SERVICE_HISTORY.SERVICE_LIST}
            element={<WithSuspense load={<ServiceHistory />} />}
          />
          <Route
            path={MAIN_ROUTES.SERVICE_HISTORY.NEW_SERVICE}
            element={<WithSuspense load={<NewService />} />}
          />
          <Route
            path={MAIN_ROUTES.SERVICE_HISTORY.VIEW_SERVICE}
            element={<WithSuspense load={<ViewService />} />}
          />
          <Route
            path={MAIN_ROUTES.SERVICE_HISTORY.EDIT_SERVICE}
            element={<WithSuspense load={<EditService />} />}
          />
          {/** Wildcard route will redirect all unhandled route to base route,
           @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.SERVICE_HISTORY.ROUTE} />}
          />
        </Route>

        {/** Transactions routes */}
        <Route path={MAIN_ROUTES.TRANSACTIONS.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}
          <Route
            index
            element={
              <Navigate to={MAIN_ROUTES.TRANSACTIONS.SERVICE_TRANSACTION} />
            }
          />
          <Route
            path={MAIN_ROUTES.TRANSACTIONS.SERVICE_TRANSACTION}
            element={<WithSuspense load={<ServiceTransactionScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.TRANSACTIONS.BANK_TRANSACTION}
            element={<WithSuspense load={<BankTransactionScreen />} />}
          />

          <Route
            path={MAIN_ROUTES.TRANSACTIONS.ADD_BANK_TRANSACTION}
            element={<WithSuspense load={<AddBankTransactionScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.TRANSACTIONS.OUTSTANDING_TRANSACTION}
            element={<WithSuspense load={<OutstandingTransactionScreen />} />}
          />

          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.MASTER_MANAGEMENT.ROUTE} />}
          />
        </Route>

        {/** Inventory routes */}
        <Route path={MAIN_ROUTES.INVENTORY.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}
          <Route
            index
            element={<Navigate to={MAIN_ROUTES.INVENTORY.INVENTORY_LIST} />}
          />
          <Route
            path={MAIN_ROUTES.INVENTORY.ROUTE}
            element={<WithSuspense load={<InventoryScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.INVENTORY.INVENTORY_LIST}
            element={<WithSuspense load={<InventoryListScreen />} />}
          />

          <Route
            path={MAIN_ROUTES.INVENTORY.NEW_INVENTORY}
            element={<WithSuspense load={<NewInventoryRequest />} />}
          />

          <Route
            path={MAIN_ROUTES.INVENTORY.EDIT_DETAILS}
            element={<WithSuspense load={<EditCarDetails />} />}
          />

          <Route
            path={MAIN_ROUTES.INVENTORY.UPLOAD_IMAGES}
            element={<WithSuspense load={<UpdateCarImages />} />}
          />

          

          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.INVENTORY.ROUTE} />}
          />
        </Route>


        <Route path={MAIN_ROUTES.LEAD.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}
          <Route
            index
            element={<Navigate to={MAIN_ROUTES.LEAD.LEAD_LIST} />}
          />
          <Route
            path={MAIN_ROUTES.LEAD.LEAD_LIST}
            element={<WithSuspense load={<LeadListScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.LEAD.CUSTOMER_LEAD}
            element={<WithSuspense load={<CustomerLead />} />}
          />
          <Route
            path={`${MAIN_ROUTES.LEAD.CUSTOMER_PREFERENCES}/:id`}
            element={<WithSuspense load={<CustomerPreferences />} />}
          />
          <Route
            path={MAIN_ROUTES.LEAD.ASSIGN_DEALERS}
            element={<WithSuspense load={<AssignDealers/>}/>}
          />
          {/* <Route
            path={MAIN_ROUTES.INVENTORY.INVENTORY_LIST}
            element={<WithSuspense load={<InventoryListScreen />} />}
          /> */}

         {/* <Route
            path={MAIN_ROUTES.INVENTORY.NEW_INVENTORY}
            element={<WithSuspense load={<NewInventoryRequest />} />}
          /> */}

        {/* <Route
            path={MAIN_ROUTES.INVENTORY.EDIT_DETAILS}
            element={<WithSuspense load={<EditCarDetails />} />}
          /> */}

          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.LEAD.ROUTE} />}
          />
        </Route>





        {/** Financial Services routes */}
        <Route path={MAIN_ROUTES.FINANCIALSERVICES.ROUTE}>
          {/** Index route will redirect to mentioned initial route,
					 @element - initial route `string` */}
          <Route
            index
            element={<Navigate to={MAIN_ROUTES.FINANCIALSERVICES.LOANS_LIST} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.ROUTE}
            element={<WithSuspense load={<FinancialServicesScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.LOANS_LIST}
            element={<WithSuspense load={<LoansListScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.ADD_LOAN}
            element={<WithSuspense load={<AddLoanScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.EDIT_LOAN}
            element={<WithSuspense load={<EditLoanScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.VIEW_LOAN}
            element={<WithSuspense load={<ViewLoanScreen />} />}
          />

          {/*  */}
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.INSURANCE_LIST}
            element={<WithSuspense load={<InsuranceListScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.ADD_INSURANCE}
            element={<WithSuspense load={<AddInsuranceScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.EDIT_INSURANCE}
            element={<WithSuspense load={<EditInsuranceScreen />} />}
          />
          <Route
            path={MAIN_ROUTES.FINANCIALSERVICES.VIEW_INSURANCE}
            element={<WithSuspense load={<ViewInsuranceScreen />} />}
          />

          {/** Wildcard route will redirect all unhandled route to base route,
					 @element - base route `string` in Navigate component */}
          <Route
            path={"*"}
            element={<Navigate to={MAIN_ROUTES.FINANCIALSERVICES.ROUTE} />}
          />
        </Route>

        {/** Wildcard route will redirect all unhandled route to base route,
				 @element - base route `string` in Navigate component */}
        <Route path={"*"} element={<Navigate to={"/"} />} />
      </Route>
    </Routes>
  );
};
