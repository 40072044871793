import { toast } from "react-toastify";

export const showToast = (type, message, options = {}, persist = false) => {
  const toastOptions = persist
    ? { autoClose: false, closeOnClick: true, ...options } 
    : options; 

  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, toastOptions); 
      break;
    case "warning":
      toast.warning(message, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    default:
      toast(message, options);
  }
};
