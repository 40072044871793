import {
  ADD_CHALLAN_REQUEST,
  CREATE_CHALLAN_PAY_REQUEST,
  EDIT_CHALLAN,
  EDIT_CHALLAN_REQUEST,
  GET_CAR_CHALLAN_LIST,
  GET_CAR_PENDING_CHALLAN_LIST,
  GET_CHALLAN_LIST,
  GET_CHALLAN_REQUEST,
} from "../../types";

const initialState = {
  challanRequestList: [],
  total: 0,
  challanList: [],
  challanPendingList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CHALLAN_PAY_REQUEST:
      return {
        ...state,
        challanRequestList: [payload, ...state.challanRequestList],
      };

    case ADD_CHALLAN_REQUEST:
      return {
        ...state,
        challanList: [payload, ...state.challanList],
      };

    case EDIT_CHALLAN_REQUEST:
      const updatedchallanRequestList = state.challanRequestList.map(
        (challanHistory) => {
          if (challanHistory.id === payload.id) {
            return { ...challanHistory, ...payload?.updateDetails };
          }
          return challanHistory;
        }
      );
      return {
        ...state,
        challanRequestList: updatedchallanRequestList,
      };

    case EDIT_CHALLAN:
      const updatedchallanList = state.challanRequestList.map(
        (challanHistory) => {
          if (challanHistory.id === payload.id) {
            return { ...challanHistory, ...payload?.updateDetails };
          }
          return challanHistory;
        }
      );
      return {
        ...state,
        challanRequestList: updatedchallanList,
      };

    case GET_CHALLAN_REQUEST:
      return {
        ...state,
        challanRequestList: payload?.data,
        total: payload?.total,
      };

    case GET_CAR_CHALLAN_LIST:
      return {
        ...state,
        challanPendingList: [],
        challanList: payload?.data,
      };
    case GET_CAR_PENDING_CHALLAN_LIST:
      return {
        ...state,
        challanPendingList: payload?.data,
      };

    default:
      return state;
  }
}
