import { ADD_LENDER_DETAIL_LOAN, ADD_PAY_DETAIL_LOAN, BASIC_DETAIL_LOAN, CLEAR_PAY_DETAIL_LOAN, DETAIL_LOAN, EDIT_BASIC_DETAIL_LOAN, EDIT_LOAN_DETAIL, FETCH_LOAN_LIST_DATA, KYC_DETAIL_LOAN, SAVE_BASIC_DETAIL_LOAN, SAVE_KYC_DETAIL_LOAN, SAVE_LENDER_DETAIL_LOAN, SAVE_LOAN_DETAIL_LOAN, SAVE_PAY_DETAIL_LOAN } from "../../types";

const initialState = {
  basicDetails: {},
  kycDetails: {},
  loanDetails: {},
  payoutDetails: {},
  lenderData: [],
  total: 0,
  loanList: [],
  challanPendingList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BASIC_DETAIL_LOAN:
      return {
        ...state,
        basicDetails: { ...state.basicDetails, ...payload },
      };
    case KYC_DETAIL_LOAN:
      return {
        ...state,
        kycDetails: { ...state.kycDetails, ...payload },
      }
    case DETAIL_LOAN:
      return {
        ...state,
        loanDetails: { ...payload, ...state.loanDetails },
      }
    case EDIT_LOAN_DETAIL:
      return {
        ...state,
        loanDetails: { ...state.loanDetails, ...payload },
      }
    case EDIT_BASIC_DETAIL_LOAN:
      return {
        ...state,
        basicDetails: { ...state.basicDetails, ...payload },
      };
    case SAVE_BASIC_DETAIL_LOAN:
      return {
        ...state,
        basicDetails: { ...state.basicDetails, ...payload },
      }
    case SAVE_KYC_DETAIL_LOAN:
      return {
        ...state,
        kycDetails: { ...state.kycDetails, ...payload },
      };
    case SAVE_LOAN_DETAIL_LOAN:
      return {
        ...state,
        loanDetails: { ...state.loanDetails, ...payload },
      };
    case SAVE_PAY_DETAIL_LOAN:
      return {
        ...state,
        payoutDetails: { ...state.payoutDetails, ...payload },
      };
    case ADD_PAY_DETAIL_LOAN:
      return {
        ...state,
        payoutDetails: { ...state.payoutDetails, ...payload },
      };
    case ADD_LENDER_DETAIL_LOAN:
      return {
        ...state,
        lenderData: [...state.lenderData, payload]
      }
    case SAVE_LENDER_DETAIL_LOAN:
      return {
        ...state,
        lenderData: [...state.lenderData, ...payload]
      }
    case FETCH_LOAN_LIST_DATA:
      return {
        ...state,
        loanList: payload?.data,
        total: payload?.total,
      };

    case CLEAR_PAY_DETAIL_LOAN:
      return {
        ...state,
        basicDetails: {},
        kycDetails: {},
        loanDetails: {},
        payoutDetails: {},
      };
    default:
      return state;
  }
}
